import http from './request'

// 活动列表
export const getActivityList = (params) => http.get('/store/mobile-activity/activity-list', params)

// 我的活动页面-我发布的
export const myActivityList = (params) => http.get('/store/mobile-activity/my-activity-list', params)

// 我的活动页面-近期数据
export const getRecentData = (params) => http.get('/store/mobile-my-activity/recent-data', params)

// 我的活动页面-我参加的
export const myJoinActivity = (params) => http.get('/store/mobile-activity/my-join-activity', params)

// 我的活动页面-我处理的
export const getServiceActivity = (params) => http.get('/store/mobile-activity/service-business', params)

// 加入的商家列表
export const getStoreJoinList = (params) => http.get('/store/mobile-activity/store-join-list', params)

// 商家加入活动的商品列表
export const getStoreJoinGoodsList = (params) => http.get('/store/mobile-activity/store-product-list', params)

// 商品列表
export const getActivityGoodsList = (params) => http.get('/store/activity/product-list', params)

// 活动列表-其他商家加入活动
export const storeJoin = (params) => http.post('/store/activity/store-join', params)

// 加入商家审核(通过、驳回)
export const storeJoinExamine = (params) => http.post('/store/mobile-activity/store-join-examine', params)

// 活动添加、活动修改
export const addActivity = (params) => http.post('/store/mobile-activity/activity-add', params)

// 活动详情
export const getActivityDetail = (params) => http.get('/store/activity/detail', params)

export const getActivityDetail2 = (params) => http.get('/store/mobile-activity/detail', params)

// 活动详情-数据
export const getActivityAata = (params) => http.get('/store/mobile-activity/activity-data', params)

// 聊一聊-聊天记录列表
export const getCommentList = (params) => http.get('/store/mobile-activity/comment-list', params)