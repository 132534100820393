/*
 * @Author: cc
 * @Date: 2020-10-24 10:25:01
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 14:43:42
 * @Description: X.X
 */
import React, { useMemo, useReducer, useState, useEffect, useRef } from "react";
import { Tabs, Badge, Toast, Modal } from "antd-mobile";
import "./index.less";

import Cookies from "js-cookie";
import { useQuery, useMutation } from "react-apollo";
import {
  LOGIN_STORE,
  SEND_SMS_CAPTCHA,
  REGISTER_STORE,
  RESET_PWD,
} from "@/graphql/login";
import { useHistory, useLocation } from "react-router-dom";
import { getQueryString, isPhone } from "@/utils";
// import { captcha } from "@/utils/captcha";
import useInterval from "@/utils/useInterval";

const tabs = [
  { title: <Badge>欢迎登录</Badge> },
  { title: <Badge>立即注册</Badge> },
];
const GoForgetPwd = () => {
  const { push } = useHistory();
  const goForgetPwd = () => {
    push("/forgetpwd");
  };
  return (
    <div className="login-forget">
      <div className="login-forget_text" onClick={goForgetPwd}>
        忘记密码
      </div>
    </div>
  );
};

const LoginComponents = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [loginMutation] = useMutation(LOGIN_STORE, {
    variables: {
      phone: phone,
      password: password,
      pos_code: getQueryString("code"),
    },
    onCompleted: (data) => {
      setLoading(false);
      if (state && state.from.pathname) {
        window.location = state.from.pathname + state.from.search;
      } else {
        window.location = "/";
      }
      Cookies.set("token", data.loginStore.remember_token);
      Cookies.set("mobile", data.loginStore.mobile);
      if (data.loginStore.store?.id) {
        Cookies.set("store_id", data.loginStore.store.id);
      } else {
        Cookies.remove("store_id");
      }
    },
    // 请求失败异常的回调
    onError(e) {
      setLoading(false);
    },
  });

  const confirmLogin = () => {
    if (loading) {
      return;
    }
    if (!phone) {
      Toast.info("请输入手机号");
    } else if (!isPhone(phone)) {
      Toast.info("手机号码格式不正确，请重新输入");
    } else if (!password) {
      Toast.info("请输入密码");
    } else {
      setLoading(true);
      loginMutation();
    }
  };

  return (
    <div className="login">
      <input
        type="text"
        placeholder="请输入手机号"
        maxLength="11"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <input
        type="password"
        placeholder="请输入登录密码"
        maxLength="20"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="btn" onClick={confirmLogin}>
        立即登录
      </div>
      <GoForgetPwd />
    </div>
  );
};

let timer = "";

const RegisterComponents = () => {
  const { pathname, state } = useLocation();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [codeSecond, setCodeSecond] = useState(0);
  const [value, setValue] = useState();

  const isRegister = useMemo(() => pathname === "/register", [pathname]);

  useInterval(() => {
    if (codeSecond > 0) {
      setCodeSecond(codeSecond - 1);
    }
  }, 1000);

  const getSmsCode = () => {
    if (!codeSecond) {
      if (!phone) {
        Toast.info("请输入手机号");
      } else {
        toCaptcha();
      }
    }
  };

  const toCaptcha = () => {
    setValue('');
    sendSmsCaptcha();
    setCodeSecond(60);
    // captcha(() => {
    //   let captchaIns;
    //   window.initNECaptcha(
    //     {
    //       element: "#captcha",
    //       captchaId: "3a0bc0da0f9449858140422b0a128f80",
    //       mode: "popup",
    //       width: "320px",
    //       enableClose: false,
    //       feedbackEnable: false,
    //       onVerify: (_, data) => {
    //         if (data && data.validate) {
    //         }
    //       },
    //     },
    //     (instance) => {
    //       captchaIns = instance;
    //       captchaIns && captchaIns.popUp();
    //     },
    //     (err) => {}
    //   );
    // });
  };

  const [sendSmsCaptcha] = useMutation(SEND_SMS_CAPTCHA, {
    onCompleted(data) {
      if (data.captcha) {
        setCodeSecond(60);
      }
    },
    // 请求失败异常的回调
    onError(e) {
      clearInterval(timer);
      setCodeSecond(0);
      if (e.graphQLErrors instanceof Array && e.graphQLErrors.length > 0) {
        e.graphQLErrors.forEach((err) => {
          const statusCode = err.code;
          if (statusCode === 41027) {
            Modal.alert("", "此手机号已注册，您可以尝试去登录", [
              { text: "取消", onPress: () => {} },
              {
                text: "去登录",
                onPress: () => push("/login"),
              },
            ]);
          }
        });
      }
    },
    // 请求带的参数
    variables: {
      mobile: phone,
      type: isRegister ? 0 : 2,
    },
  });

  const [registerMutation] = useMutation(
    isRegister ? REGISTER_STORE : RESET_PWD,
    {
      variables: {
        phone: phone,
        password: password,
        code: code,
        codeType: isRegister ? 0 : 2,
        pos_code: getQueryString("code"),
        type: 2,
      },
      onCompleted: (data) => {
        setLoading(false);
        let t = isRegister
          ? data.registerStore.remember_token
          : data.resetPassword.remember_token;
        let mobile = isRegister
          ? data.registerStore.mobile
          : data.resetPassword.mobile;
        Cookies.set("token", t);
        Cookies.set("mobile", mobile);
        if (state && state.from.pathname) {
          window.location = state.from.pathname + state.from.search;
        } else {
          window.location = "/";
        }
      },
      onError(e) {
        setLoading(false);
      },
    }
  );

  const onRegister = () => {
    console.log(password);
    if (loading) {
      return;
    }
    if (!phone) {
      Toast.info("请输入手机号");
    } else if (!isPhone(phone)) {
      Toast.info("手机号码格式不正确，请重新输入");
    } else if (!code) {
      Toast.info("请输入验证码");
    } else if (!password) {
      Toast.info("请输入密码");
    } else if (password.length < 6) {
      Toast.info("请输入6位及以上密码");
    } else if (password !== confirm_password) {
      Toast.info("密码不一致");
    } else {
      setLoading(true);
      registerMutation();
    }
  };

  return (
    <div className="login">
      <input
        type="text"
        placeholder="请输入手机号"
        name="phone"
        maxLength="11"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <div className="verifica-code">
        <input
          type="text"
          name="code"
          autoComplete="off"
          placeholder="请输入验证码"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <input
          type="password"
          autoComplete="new-password"
          style={{ display: "none" }}
        />
        <div onClick={getSmsCode} className="code">
          <span className="href">
            {!!codeSecond ? codeSecond + "s后重新获取" : "获取验证码"}
          </span>
        </div>
      </div>
      <input
        type="password"
        name="password"
        placeholder="请输入新密码"
        maxLength="20"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        name="confirm_password"
        placeholder="请确认新密码"
        maxLength="20"
        value={confirm_password}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <div className="btn" onClick={onRegister}>
        立即登录
      </div>
      {isRegister && <GoForgetPwd />}
      <div id="captcha" />
    </div>
  );
};

const Login = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const isLogin = useMemo(() => pathname === "/login", [pathname]);
  const isForgetPwd = useMemo(() => pathname === "/forgetpwd", [pathname]);

  useEffect(() => {
    const token = getQueryString('token')
    if (token) {
      Cookies.set("token", token);
      window.location.replace('/')
    }
  }, [])
  const onTabClick = (_, index) => {
    const code = getQueryString("code");
    const baseUrl = index === 0 ? "/login" : "/register";
    const finalUrl = code ? baseUrl + "?code=" + code : baseUrl;
    push(finalUrl);
  };
  return (
    <div className="sign">
      <header className="sign-title">商家管理后台</header>
      <article className="sign-wrapper">
        <section className="sign-card">
          {isForgetPwd ? (
            <>
              <div className="sign-title sign-title_sub">忘记密码</div>
              <RegisterComponents />
            </>
          ) : (
            <Tabs
              tabs={tabs}
              page={isLogin ? 0 : 1}
              onTabClick={onTabClick}
              onChange={onTabClick}
              tabBarUnderlineStyle={{ right: isLogin ? "50%" : "0%" }}
              tabBarActiveTextColor={"#000"}
              tabBarInactiveTextColor={"#999"}
            >
              <LoginComponents />
              <RegisterComponents />
            </Tabs>
          )}
        </section>
      </article>
      <footer className="sign-footer">
        <div className="sign-footer_text">
          登录代表您已同意典栈生活圈
          <div className="sign-footer_text_theme">
            <span onClick={() => push(`/resources?name=store_agreement`)}>
              用户协议
            </span>
            、
            <span
              onClick={() => push(`/resources?name=store_privacy_agreement`)}
            >
              隐私协议
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Login;
