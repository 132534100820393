import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Image, Toast } from 'antd-mobile-v5'
import ZFooterBar from '@/components/zFooterBar'
import qs from 'query-string'
import { diningOut, getOrderDetail, isOrderInProgress, ORDER_STATUS, REMARK_TYPE_STATUS } from '../../apis/order'
import { copy, showConfirm, timestampToString, timeStrToTimestamp } from '../../utils'
import './index.scss'

const transList = {
    DADA: '达达快递',
    SFTC: '顺丰同城'
}

const OrderDetail = () => {
    const { push } = useHistory()
    const id = qs.parse(useLocation().search).id
    const [info, setInfo] = useState(null)
    const title = useMemo(() => {
        if (!info) return ''
        return ({
            ...ORDER_STATUS,
            2: info.order_type === 1 && info.is_self_help === 1 ? '制作中' : '待发货',
            4: info.order_type === 2 ? '待取餐' : (info.order_type === 1 && info.is_self_help === 1 ? '待核销' : '配送中'),
            6: '退款成功'
        })[info.status]
    }, [info])
    const subtitle = useMemo(() => {
        if (!info) return ''
        let text = ''
        switch (info.status) {
        case 0:
            text = '等待买家付款'
            break
        case 1:
            if (info.order_type === 0) {
                text = '买家已签收'
            } else if (info.order_type === 1) {
                text = info.is_self_help === 1 ? '顾客已取走，订单完成' : '骑手已送达'
            } else {
                text = '顾客已取走，订单完成'
            }
            break
        case 2:
            if (info.order_type === 0) {
                text = '买家已付款，请尽快发货'
            } else if (info.order_type === 1) {
                text = info.is_self_help === 1 ? '正在准备餐品' : '骑手已接单，等待骑手到达'
            } else {
                text = '正在准备餐品'
            }
            break
        case 3:
            text = '顾客未在规定时间内完成支付，订单已自动取消'
            break
        case 4:
            if (info.order_type === 0) {
                text = '商品正在配送中!'
            } else if (info.order_type === 1) {
                text = info.is_self_help === 1 ? '等待顾客到店自取，请及时准备餐品' : '骑手正在配送'
            } else {
                text = '餐品已备好，等待顾客自取'
            }
            break
        case 11:
            if (info.order_type === 0) {
                text = '订单已超时，请联系快递了解情况并处理订单'
            } else if (info.order_type === 1) {
                text = '订单已超时，请联系骑手了解情况并处理订单'
            }
            break
        }
        return text
    }, [info])

    useEffect(() => {
        if (id) {
            getData()
        }
    }, [])

    const getData = async() => {
        try {
            const { data } = await getOrderDetail({ id })
            const t = (48*60*60*1000) - (Date.now() - data.pay_at * 1000)
            let isRefund = false
            if (isOrderInProgress(data.status) || (data.status === 1 && t > 0)) {
                isRefund = true
            }
            data.isRefund = isRefund
            if (data.order_refund && data.order_refund.status === 2) {
                data.refund_amount = data.order_refund.refund_amount
            }
            if (data.order_type === 0) {
                data.express_list = data.order_items.filter(t => !!t.merge_express_no).map(t => `${t.merge_express_code} ${t.merge_express_no}`)
            }
            setInfo(data)
        } catch (e) {
            console.log(e)
        }
    }

    const copyAddress = () => {
        copy(`${info.address.name} ${info.address.mobile} ${info.address.province}${info.address.city}${info.address.area}${info.address.address}`)
    }

    // 出餐
    const onDiningOut = () => {
        showConfirm({
            title: '是否确认出餐？',
            content: '请确认菜品无误后再出餐',
            confirmText: '确认',
            onConfirm: async () => {
                try {
                    const res = await diningOut(id)
                    if (res.errors.code === 1) {
                        Toast.show('出餐成功')
                        getData()
                    }
                } catch(e) {
                    console.log(e)
                }
            }
        })
    }

    return (
        <div className="orderDetail">
            {!!info && <div className="content">
                <div className="header">
                    <div className="flex">
                        {info.status === 0 && <span className="icon-daizhifu"></span>}
                        {info.status === 1 && <span className="icon-yiwanceng"></span>}
                        {info.status === 2 && <span className="icon-daifahuo"></span>}
                        {info.status === 4 && <>
                            {info.order_type === 0 && <span className="icon-wuliu"></span>}
                            {info.order_type === 1 && info.is_self_help === 0 && <span className="icon-peisongzhong"></span>}
                            {info.order_type === 1 && info.is_self_help === 1 && <span className="icon-daihexiao"></span>}
                            {info.order_type === 2 && <span className="icon-daiqucan"></span>}
                        </>}
                        {info.status === 5 && <span className="icon-tuikuanzhong"></span>}
                        {info.status === 6 && <span className="icon-yituikuan"></span>}
                        {[8,9,10].includes(info.status) && <span className="icon-daihexiao"></span>}
                        {[3,7,71,72].includes(info.status) && <span className="icon-yiquxiao"></span>}
                        {info.status === 11 && <span className="icon-peisongyichang"></span>}
                        {info.status === 5 ? (
                            <div className="flex-1" style={{ marginLeft: 6 }}>
                                <div className="item-title">{title}</div>
                                <div className="item-subtl">{REMARK_TYPE_STATUS[info.order_refund?.remark_type] || '买家发起退款'}</div>
                                <div className="item-subtl">申请退款原因:{info.order_refund?.remark_type_text}</div>
                                <div className="item-subtl flex-a-center">退款金额：<span className="item-amount">￥{info.order_refund?.amount}</span></div>
                            </div>
                        ) : (
                            <div className="flex-1" style={{ marginLeft: 6 }}>
                                <div className={`item-title ${!subtitle ? 'lh32' : ''}`}>{title}</div>
                                {!!subtitle && <div className="item-subtl">{subtitle}</div>}
                            </div>
                        )}
                    </div>
                    {info.status === 6 && <div className="item-cells">
                        <div className="item-cell flex-space">
                            <div>退款金额</div>
                            <div className="item-cell-amount">¥{info.refund_amount}</div>
                        </div>
                        <div className="item-cell flex-space">
                            <div>退款账户</div>
                            <div>{({ 0: '余额', 1: '微信' })[info.pay_type]}</div>
                        </div>
                    </div>}
                    {info.order_type === 1 && info.is_self_help === 0 && !!info.express_code && <div className="item-text flex-a-center">
                        <span>{info.express_code ? transList[info.express_code] : '-'} {info.delivery_name || '-'} {info.delivery_mobile || '-'}</span>
                        <span className="icon-phone1" style={{ marginLeft: 6 }}></span>
                    </div>}
                    {info.order_type === 0 && !!info.express_list.length && <div className="item-text">
                        {info.express_list.map((t, i) => (<div className="flex-a-center" key={i}>
                            <span>{t}</span>
                            {![1,3,5,6,7,71,72].includes(info.status) && <span className="icon-copy" style={{ marginLeft: 6 }} onClick={() => copy(t)}></span>}
                        </div>))}
                    </div>}
                    {(info.isRefund || (info.status === 2 && ([0,2].includes(info.status) || info.is_self_help === 1))) && <div className="item-footer flex flex-end">
                        {info.isRefund && <div className="footer-btn" onClick={() => push(`/refund?id=${id}`)}>退款</div>}
                        {info.order_type === 0 && info.status === 2 && <div className="footer-btn main" onClick={() => push(`/delivery?id=${id}`)}>发货</div>}
                        {(info.order_type === 2 || info.is_self_help === 1) && info.status === 2 && <div className="footer-btn main" onClick={onDiningOut}>出餐</div>}
                    </div>}
                </div>
                {(info.order_type === 0 || (info.order_type === 1 && info.is_self_help === 0)) && <div className="card">
                    <div className="card-header flex-space">
                        <div className="card-title">收货信息</div>
                    </div>
                    <div className="card-inner">
                        <div style={{ marginBottom: 3 }}>{info.address?.name || '-'}</div>
                        <div style={{ marginBottom: 3 }}>{info.address?.mobile || '-'}</div>
                        <div>{info.address ? `${info.address.province} ${info.address.city} ${info.address.area} ${info.address.address}` : '-'}</div>
                        {info.status === 2 && <div className="card-footer flex flex-end">
                            <div className="card-btn" onClick={() => push(`/editAddress?id=${id}`)}>修改地址</div>
                            <div className="card-btn" onClick={copyAddress}>复制</div>
                        </div>}
                    </div>
                </div>}
                {info.order_type === 1 && info.is_self_help === 1 && <div className="card">
                    <div className="card-header flex-space">
                        <div className="card-title">预留电话</div>
                    </div>
                    <div className="card-inner">
                        {!!info.address ? <div className="flex-a-center">
                            <span>{info.address.mobile}</span>
                            <span className="icon-phone1" style={{ marginLeft: 6 }}></span>
                        </div> : <div>-</div>}
                    </div>
                </div>}
                <div className="card">
                    <div className="card-header flex-space">
                        <div className="card-title">买家留言</div>
                    </div>
                    <div className="card-inner">
                        <div>{info.remake || '-'}</div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header flex-space">
                        <div className="card-title">商品信息</div>
                        <div className="userInfo flex-a-center">
                            <img className="userInfo-img" src={info.user?.avatar} />
                            <div className="userInfo-name">{info.user?.username || '微信用户'}</div>
                            <span className="icon-message"></span>
                        </div>
                    </div>
                    <div className="card-inner">
                        {info.order_items.map(good => (<div className="item-good flex" key={good.id}>
                            <div className="item-good__media flex-shrink-0">
                                <Image className="item-good__img" src={good.product_cover} />
                            </div>
                            <div className="item-good__inner flex-1">
                                <div className="item-good__title ell2">{good.product_name}</div>
                                <div className="item-good__subtl">{good.product_spec_val}</div>
                            </div>
                            <div className="item-good__after flex-column align-end">
                                <div className="item-good__amount">¥{good.product_price}</div>
                                <div className="item-good__number">x{good.number}</div>
                            </div>
                        </div>))}
                        <div className="item-cells">
                            <div className="item-cell flex-space">
                                <div>商品总价</div>
                                <div className="b">¥{info.product_amount}</div>
                            </div>
                            <div className="item-cell flex-space">
                                <div>打包费</div>
                                <div className="b">+¥{info.package_price}</div>
                            </div>
                            <div className="item-cell flex-space">
                                <div>{info.order_type === 0 ? '运费' : '配送费'}</div>
                                <div className="b">+¥{info.freight}</div>
                            </div>
                            {info.numeral_coupon_amount > 0 && <div className="item-cell flex-space">
                                <div>平台优惠券</div>
                                <div className="b">-¥{info.numeral_coupon_amount}</div>
                            </div>}
                        </div>
                        <div className="flex-a-center flex-end">
                            <span className="item-total">{info.status === 0 ? '买家需支付' : ({ 0: '余额支付', 1: '微信支付' })[info.pay_type]} ¥{info.total}</span>
                        </div>
                    </div>
                </div>
                <div className="cell-card">
                    <div className="item flex-space">
                        <span>订单编号</span>
                        <div className="flex-a-center">
                            <span>{info.order_no}</span>
                            <span className="icon-copy" style={{ marginLeft: 3 }} onClick={() => copy(info.order_no)}></span>
                        </div>
                    </div>
                    <div className="item flex-space">
                        <span>下单时间</span>
                        <span>{timestampToString(info.created_at * 1000, 2)}</span>
                    </div>
                    {!!info.pay_at && <div className="item flex-space">
                        <span>付款时间</span>
                        <span>{timestampToString(timeStrToTimestamp(info.pay_at), 2)}</span>
                    </div>}
                </div>
            </div>}
            {!!info && (info.isRefund || (info.status === 2 && ([0,2].includes(info.status) || info.is_self_help === 1))) && <ZFooterBar className="flex-end">
                {info.isRefund && <div className="footer-btn" onClick={() => push(`/refund?id=${id}`)}>退款</div>}
                {info.order_type === 0 && info.status === 2 && <div className="footer-btn main" onClick={() => push(`/delivery?id=${id}`)}>发货</div>}
                {(info.order_type === 2 || info.is_self_help === 1) && info.status === 2 && <div className="footer-btn main" onClick={onDiningOut}>出餐</div>}
            </ZFooterBar>}
        </div>
    )
}

export default OrderDetail