import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CascadePicker, Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import ZFooterBar from '@/components/zFooterBar'
import client from '@/graphql/apollo-config'
import { REGION } from '@/graphql/store'
import qs from 'query-string'
import { editOrderAddress, getOrderDetail } from '../../apis/order'
import { isPhone } from '../../utils'
import './index.scss'

const EditAddress = () => {
    const history = useHistory()
    const id = qs.parse(useLocation().search).id
    const [form, setForm] = useState({
        name: '',
        mobile: '',
        province: '',
        city: '',
        area: '',
        address: '',
    })
    const [visible, setVisible] = useState(false)
    const [region, setRegion] = useState([])
    const [regionValue, setRegionValue] = useState([])

    useEffect(() => {
        query()
        if (id) {
            getData()
        }
    }, [])
    useEffect(() => {
        if (region.length > 0) {
            const value = []
            const province = region.find(t => t.label === form.province)
            const city = ((province && province.children) || []).find(t => t.label === form.city)
            const area = ((city && city.children) || []).find(t => t.label === form.area)
            province && value.push(province.value)
            city && value.push(city.value)
            area && value.push(area.value)
            setRegionValue(value)
        }
    }, [region])

    const query = async () => {
        try {
            const {data} = await client.query({
                query: REGION,
                fetchPolicy: "no-cache"
            })
            if (data) {
                // console.log('REGION  =  =====',data)
                setRegion(data.region)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async() => {
        try {
            const { data } = await getOrderDetail({ id })
            const { address } = data
            !!address && setForm({
                name: address.name,
                mobile: address.mobile,
                province: address.province,
                city: address.city,
                area: address.area,
                address: address.address,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const setValue = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const onSubmit = async() => {
        let msg = ''
        if (!form.name) {
            msg = '请输入收件姓名'
        } else if (!form.mobile) {
            msg = '请输入手机号码'
        } else if (!isPhone(form.mobile)) {
            msg = '请输入正确的手机号码'
        } else if (!form.province) {
            msg = '请选择所在地区'
        } else if (!form.address) {
            msg = '请输入详细地址'
        }
        if (msg) {
            Toast.show(msg)
            return
        }
        try {
            const { data } = await editOrderAddress({
                id,
                ...form
            })
            Toast.show('修改成功')
            history.goBack()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="editAddress">
            <div className="cells">
                <div className="cell">
                    <div className="item-label">收件姓名</div>
                    <div className="item-inner flex-1 border">
                        <input type="text" className="item-input" value={form.name} onChange={e => setValue('name', e.target.value)} />
                    </div>
                </div>
                <div className="cell">
                    <div className="item-label">手机号码</div>
                    <div className="item-inner flex-1 border">
                        <input type="tel" className="item-input" maxLength={11} value={form.mobile} onChange={e => setValue('mobile', e.target.value)} />
                    </div>
                </div>
                <div className="cell" onClick={() => setVisible(true)}>
                    <div className="item-label">所在地区</div>
                    <div className="item-inner flex-1 border">
                        <div className="item-input">{form.province} {form.city} {form.area}</div>
                    </div>
                    <RightOutline color="#8B8B92" fontSize={14} style={{ marginLeft: 6 }} />
                </div>
                <div className="cell">
                    <div className="item-label">详细地址</div>
                    <div className="item-inner flex-1">
                        <input type="text" className="item-input" value={form.address} onChange={e => setValue('address', e.target.value)} />
                    </div>
                </div>
            </div>
            <ZFooterBar>
                <div className="footer-btn flex-1" onClick={onSubmit}>确认修改</div>
            </ZFooterBar>
            <CascadePicker
                title='所在地区'
                options={region}
                value={regionValue}
                visible={visible}
                loading={region.length === 0}
                onClose={() => setVisible(false)}
                onConfirm={(val, extend) => {
                    const items = extend.items
                    setRegionValue(val)
                    setForm({
                        ...form,
                        province: items[0].label,
                        city: items[1].label,
                        area: items[2].label,
                    })
                }}
            />
        </div>
    )
}

export default EditAddress