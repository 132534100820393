import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Cascader, Picker, Toast } from 'antd-mobile-v5'
import Upload from '@/components/upload'
import ZFooterBar from '../../components/zFooterBar'
import CouponModal from '../../components/couponModal'
import ZModal from '../../components/zModal'
import { RightOutline } from 'antd-mobile-icons'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './index.scss'
import client from '@/graphql/apollo-config'
import { ADD_GOODS, EDIT_GOODS, GOODS_DETAIL, USER_AND_LOGISTICS, USABLE_LOGISTICS, Get_Store_Categorys, GET_CATEGORY } from '@/graphql/goods'
import { uploadImages } from '../../apis/common'
import { verification } from '../../utils'
import app from '@/app.json'
import qs from 'query-string'

const AddGoods = (props) => {
    const history = useHistory()
    const { id } = qs.parse(useLocation().search)
    const [fileList1, setFileList1] = useState([])
    const [fileList2, setFileList2] = useState([])
    const [fileList3, setFileList3] = useState([])
    const [content, setContent] = useState('')
    const quillRef = useRef()
    const [form, setForm] = useState({
        name: '',
        sales_point: '',
        price: '',
        first_price: '',
        stock: '',
        order_limit: '',
        pusherman_redpack_rate: '',
        product_category_id: '',
        category_ids: [],
        numeral_coupon: '',
    })
    const [OSSData, setOSSData] = useState({})
    const [logistics, setLogistics] = useState([]); // 快递类型
    const [productCategroy, setProductCategroy] = useState([]); // 商品类目
    const [categorys, setCategorys] = useState([]) // 平台分类
    const [skuList, setSkuList] = useState({
        // id: 0,
        name: '规格',
        list: []
    })
    const [couponVisible, setCouponVisible] = useState(false)
    const [deliveryType, setDeliveryType] = useState(3) // 配送方式  0:同城配送  1:自有配送  2:普通物流  3:默认
    const [isSelfDelivery, setSelfDelivery] = useState(0) // 是否自取  1是 0否
    const [deliveryVisible, setDeliveryVisible] = useState(false)
    const deliveryText = useMemo(() => {
        const arr = []
        if (deliveryType === 0) {
            arr.push('同城配送')
        } else if (deliveryType === 2) {
            arr.push('快递配送')
        }
        if (isSelfDelivery === 1) {
            arr.push('自有配送')
        }
        return arr.join('、')
    }, [deliveryType, isSelfDelivery])

    const modules = useMemo(() => ({
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['image'],
        ],
    }), [])

    useEffect(() => {
        init()
        getCategorys()
        if (id) {
            console.log(id)
            getProductInfo()
        }
    }, [])

    const setFormData = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const handleImageUpload = () => {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.click()
        input.onchange = async() => {
            const file = input.files[0]
            // 这里应该处理图片上传到服务器的逻辑
            // 例如使用fetch或者其他HTTP库上传图片
            // 上传成功后，将图片URL设置到编辑器中
            const formData = new FormData()
            formData.append('file', file)
            try {
                const { data } = await uploadImages(formData)
                const url = app.oss_host + data
                const cursorPosition = quillRef.current.getEditor().getSelection(true).index
                quillRef.current.getEditor().insertEmbed(cursorPosition, 'image', url);
                quillRef.current.getEditor().setSelection(cursorPosition + 1)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const setQuillEdit = (r) => {
        quillRef.current = r
        if (r) {
            r.getEditor().getModule('toolbar').handlers.image = handleImageUpload
        }
    }
    const init = async () => {
        try {
            const { data } = await client.query({
                query: USER_AND_LOGISTICS,
                fetchPolicy: "no-cache",
            });
            if (data) {
                setOSSData(data.oss);
                const u = data.user.store;
                client.query({
                    query: USABLE_LOGISTICS,
                    variables: {
                        delivery_type: u.delivery_type,
                    },
                })
                .then(({ data: { usablelogistics } }) => {
                    setLogistics(usablelogistics);
                });
                if (u.product_type == 0) {
                    getProductCategory();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    // 商品信息
    const getProductInfo = async () => {
        try {
            const { data } = await client.query({
                query: GOODS_DETAIL,
                variables: {
                    product_id: id,
                },
                fetchPolicy: 'no-cache',
            })
            if (data && data.GoodsDetail) {
                const {specs_types, categories, ...info} = data.GoodsDetail
                setFileList1([{ url: info.cover.url }])
                setFileList2(info.images)
                info.video && setFileList3([{ url: info.video.url }])
                setContent(info.content)
                setForm({
                    name: info.name,
                    sales_point: info.sales_point,
                    price: info.price + '',
                    first_price: info.first_price + '',
                    stock: info.stock + '',
                    order_limit: info.order_limit + '',
                    pusherman_redpack_rate: info.pusherman_redpack_rate + '',
                    product_category_id: info.product_category_id + '',
                    category_ids: categories.map(t => t.id),
                    numeral_coupon: info.numeral_coupon_id ? {
                        id: info.numeral_coupon_id,
                        name: info.numeral_coupon_name
                    } : '',
                })
                if (specs_types.length > 0) {
                    setSkuList({
                        id: specs_types[0].id,
                        name: specs_types[0].name,
                        list: specs_types[0].product_specs
                    })
                }
                setDeliveryType(info.delivery_type)
                setSelfDelivery(info.is_self_delivery)
            }
        } catch (e) {
            console.error(e)
        }
    }

    // 商品类目
    const getProductCategory = async () => {
        try {
            const { data } = await client.query({
                query: Get_Store_Categorys,
                variables: {
                limit: 100,
                page: 1,
                },
                fetchPolicy: "no-cache",
            });
            setProductCategroy(
                data.ProductCategories.data.map(t => ({
                    label: t.name,
                    value: t.id
                }))
            );
        } catch (error) {
            console.error(error);
        }
    }

    // 平台分类
    const getCategorys = async () => {
        try {
            const { data } = await client.query({
                query: GET_CATEGORY,
                fetchPolicy: 'no-cache',
            })
            setCategorys(data.categorys)
        } catch (e) {
            console.log(e);
        }
    }

    const onAddSku = () => {
        const item = {
            name: '',
            sku: '',
            price: '',
            stock: '',
            offer_price: '',
        }
        setSkuList({
            ...skuList,
            list: [...skuList.list, item]
        })
    }
    const onDelSku = (index) => {
        const _list = [...skuList.list]
        _list.splice(index, 1)
        setSkuList({
            ...skuList,
            list: _list
        })
    }

    const onSkuItemChange = (key, value, index) => {
        const _list = [...skuList.list]
        const item = _list[index]
        _list.splice(index, 1, {
            ...item,
            [key]: value
        })
        setSkuList({
            ...skuList,
            list: _list
        })
    }

    const rule = [
        {
            rule: !fileList1.length,
            title: '请上传封面'
        },
        {
            rule: !fileList2.length,
            title: '请上传商品图片'
        },
        {
            rule: !form.name,
            title: '请输入商品标题'
        },
        {
            rule: !form.product_category_id,
            title: '请选择商品类目'
        },
        {
            rule: !form.category_ids.length,
            title: '请选择平台分类'
        },
        {
            rule: !form.sales_point,
            title: '请输入商品卖点'
        },
        {
            rule: !content,
            title: '请输入商品描述'
        },
        {
            rule: !form.price,
            title: '请输入销售价'
        },
        {
            rule: !form.first_price,
            title: '请输入划线价'
        },
        {
            rule: !form.stock,
            title: '请输入库存'
        },
        {
            rule: !form.order_limit,
            title: '请输入每订单限购'
        },
        {
            rule: !form.pusherman_redpack_rate,
            title: '请输入让利佣金'
        },
        {
            rule: form.pusherman_redpack_rate && isNaN(+form.pusherman_redpack_rate),
            title: '让利佣金必须为数字'
        },
        {
            rule: form.pusherman_redpack_rate && (+form.pusherman_redpack_rate < 3 || +form.pusherman_redpack_rate > 100),
            title: '让利佣金必须为3-100整数'
        },
        {
            rule: deliveryType === 3 && isSelfDelivery === 0,
            title: '请选择配送方式'
        }
    ]

    const onSubmit = async() => {
        if (!verification(rule)) return
        if (skuList.list.length > 0) {
            const is = skuList.list.every((item) => {
                if (!item.name) {
                    Toast.show('请输入规格名称')
                    return false
                } else if (!item.sku) {
                    Toast.show('请输入sku编码')
                    return false
                } else if (!item.price) {
                    Toast.show('请输入规格价')
                    return false
                } else if (!item.stock) {
                    Toast.show('请输入库存')
                    return false
                }
                return true
            })
            if (!is) return
        }
        try {
            const { numeral_coupon, ..._form } = form
            const { list, ...skuData } = skuList
            const specs = list.length > 0 ? [{ ...skuData, product_specs: list }] : []
            const params = {
                ..._form,
                content,
                images: fileList2.map((t) => t.url.replace(app.oss_host, '')),
                cover: fileList1.length > 0 ? fileList1[0].url.replace(app.oss_host, '') : '',
                video: fileList3.length > 0 ? fileList3[0].url.replace(app.oss_host, '') : '',
                numeral_coupon_id: numeral_coupon ? numeral_coupon.id : undefined,
                is_deposit: 0,          // 是否押金商品
                is_must: 0,             // 是否必点
                status: 1,              // 是否上架
                supplier_id: 0,         // 供应商
                product_offer_price: 0, // 供货价
                delivery_type: deliveryType,
                is_self_delivery: isSelfDelivery,
                specs_types: JSON.stringify(specs)
            }
            if (id) {
                params.product_id = id
            }
            const { data } = await client.mutate({
                mutation: id ? EDIT_GOODS : ADD_GOODS,
                variables: params,
                fetchPolicy: "no-cache",
            })
            if (data[id ? 'EditGoods' : 'AddGoods']) {
                Toast.show('提交成功')
                id ? history.goBack() : history.replace('/goodsManage')
            }
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <div className="addGoods">
            <div className="content">
                <div className="img-card">
                    <Upload
                        value={fileList1}
                        type="image"
                        title="商品封面"
                        required
                        onChange={setFileList1}
                    />
                    <Upload
                        value={fileList2}
                        count={5}
                        type="image"
                        title="商品图片"
                        required
                        onChange={setFileList2}
                    />
                    <Upload
                        value={fileList3}
                        type="video"
                        title="商品视频"
                        onChange={setFileList3}
                    />
                    <div className="block-title flex">商品标题<span className="required">*</span></div>
                    <div className="item-textarea">
                        <textarea
                            className="item-textarea__inner onLen"
                            placeholder="请输入商品标题 (商品标题组成: 商品描述+规格)"
                            rows={2}
                            value={form.name}
                            onChange={(e) => {
                                setFormData('name', e.target.value)
                            }}
                        ></textarea>
                    </div>
                </div>
                <div className="card">
                    <Picker
                        columns={[productCategroy]}
                        value={[form.product_category_id]}
                        onConfirm={v => {
                            setFormData('product_category_id', v[0])
                        }}
                    >
                        {(item, actions) => (
                            <div className="item-cell border flex-space" onClick={actions.open}>
                                <div className="block-title flex">商品类目<span className="required">*</span></div>
                                {item[0]
                                    ? <div className="flex-a-center"><span className="item-label">{item[0].label}</span><RightOutline /></div>
                                    : <div className="flex-a-center">请选择<RightOutline /></div>}
                            </div>
                        )}
                    </Picker>
                    <Cascader
                        options={categorys}
                        value={form.category_ids}
                        onConfirm={v => {
                            setFormData('category_ids', v)
                        }}
                    >
                        {(items, actions) => (
                            <div className="item-cell border flex-space" onClick={actions.open}>
                                <div className="block-title flex">平台分类<span className="required">*</span></div>
                                {items.length > 0
                                    ? <div className="flex-a-center"><span className="item-label">{items[items.length-1].label}</span><RightOutline /></div>
                                    : <div className="flex-a-center">请选择<RightOutline /></div>}
                            </div>
                        )}
                    </Cascader>
                    <div className="item-cell">
                        <div className="block-title flex">商品卖点<span className="required">*</span></div>
                        <div className="item-textarea">
                            <textarea
                                className="item-textarea__inner"
                                placeholder="请输入商品描述以提升转化 (若未编辑，商品发布后轮播图将自动填充至图文详情)"
                                rows={2}
                                maxLength={200}
                                value={form.sales_point}
                                onChange={(e) => {
                                    setFormData('sales_point', e.target.value)
                                }}
                            ></textarea>
                            <div className="item-textarea__number">{form.sales_point.length}/200</div>
                        </div>
                    </div>
                    <div className="item-cell">
                        <div className="block-title flex">图文描述<span className="required">*</span></div>
                        <ReactQuill
                            ref={el => setQuillEdit(el)}
                            modules={modules}
                            value={content}
                            onChange={setContent}
                        />
                    </div>
                </div>
                <div className="card">
                    <div className="item-cell border flex-space">
                        <div className="block-title flex">销售价<span className="required">*</span></div>
                        <div className="item-input">
                            <input
                                className="item-input__inner"
                                type="number"
                                value={form.price}
                                placeholder="请输入销售价"
                                onChange={(e) => {
                                    setFormData('price', e.target.value)
                                }}
                            />
                            <span className="item-input__after">元</span>
                        </div>
                    </div>
                    <div className="item-cell border flex-space">
                        <div className="block-title flex">划线价<span className="required">*</span></div>
                        <div className="item-input">
                            <input
                                className="item-input__inner"
                                type="number"
                                value={form.first_price}
                                placeholder="请输入划线价"
                                onChange={(e) => {
                                    setFormData('first_price', e.target.value)
                                }}
                            />
                            <span className="item-input__after">元</span>
                        </div>
                    </div>
                    <div className="item-cell border flex-space">
                        <div className="block-title flex">库存<span className="required">*</span></div>
                        <div className="item-input">
                            <input
                                className="item-input__inner"
                                type="number"
                                value={form.stock}
                                placeholder="请输库存"
                                onChange={(e) => {
                                    setFormData('stock', e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="item-cell border flex-space">
                        <div className="block-title flex">每订单限购<span className="required">*</span></div>
                        <div className="item-input">
                            <input
                                className="item-input__inner"
                                type="number"
                                value={form.order_limit}
                                placeholder="请输限购数量"
                                onChange={(e) => {
                                    setFormData('order_limit', e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="item-cell flex-space">
                        <div className="block-title flex">让利佣金<span className="required">*</span></div>
                        <div className="item-input w-146">
                            <input
                                className="item-input__inner"
                                type="number"
                                value={form.pusherman_redpack_rate}
                                placeholder="请输入3~100整数"
                                onChange={(e) => {
                                    setFormData('pusherman_redpack_rate', e.target.value)
                                }}
                            />
                            <span className="item-input__after">%</span>
                        </div>
                    </div>
                    <div className="item-cell flex-space" onClick={() => setCouponVisible(true)}>
                        <div className="block-title flex">消费领券</div>
                        {form.numeral_coupon
                            ? <div className="flex-a-center"><span className="item-label">{form.numeral_coupon.name}</span><RightOutline /></div>
                            : <div className="flex-a-center">请选择<RightOutline /></div>}
                    </div>
                </div>
                <div className="card">
                    <div className="item-cell border flex-space">
                        <div className="block-title">规格类型</div>
                        <div className="item-label flex-a-center" onClick={onAddSku}><span className="icon-plus" style={{ marginRight: 3 }}></span>新增规格</div>
                    </div>
                    {skuList.list.map((item, index) => (<div key={index}>
                        <div className="item-cell item-sku border flex-space">
                            <div className="item-sku__inner flex-a-center">
                                <span className="icon-reduce" style={{ marginRight: 6 }} onClick={() => onDelSku(index)}></span>
                                <input
                                    type="text"
                                    className="item-sku__input"
                                    placeholder="请输入规格名称"
                                    value={item.name}
                                    onChange={e => onSkuItemChange('name', e.target.value, index)}
                                />
                            </div>
                            <div className="flex-a-center">
                                <span>SKU编码</span>
                                <div className="item-input w-101">
                                    <input
                                        className="item-input__inner"
                                        type="number"
                                        value={item.sku}
                                        onChange={e => onSkuItemChange('sku', e.target.value, index)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="item-cell flex-space">
                            <div></div>
                            <div className="flex-a-center">
                                <span>规格价</span>
                                <div className="item-input w-101">
                                    <input
                                        className="item-input__inner"
                                        type="number"
                                        value={item.price}
                                        onChange={e => onSkuItemChange('price', e.target.value, index)}
                                    />
                                    <span className="item-input__after">元</span>
                                </div>
                            </div>
                        </div>
                        <div className="item-cell flex-space">
                            <div></div>
                            <div className="flex-a-center">
                                <span>库存</span>
                                <div className="item-input w-101">
                                    <input
                                        className="item-input__inner"
                                        type="number"
                                        value={item.stock}
                                        onChange={e => onSkuItemChange('stock', e.target.value, index)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="item-cell border flex-space">
                            <div></div>
                            <div className="flex-a-center">
                                <span>供货价</span>
                                <div className="item-input w-101">
                                    <input
                                        className="item-input__inner"
                                        type="number"
                                        value={item.offer_price}
                                        onChange={e => onSkuItemChange('offer_price', e.target.value, index)}
                                    />
                                    <span className="item-input__after">元</span>
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>
                <div className="card">
                    <div className="item-cell flex-space" onClick={() => setDeliveryVisible(true)}>
                        <div className="block-title flex">配送方式<span className="required">*</span></div>
                        {deliveryText
                            ? <div className="flex-a-center"><span className="item-label">{deliveryText}</span><RightOutline /></div>
                            : <div className="flex-a-center">请选择<RightOutline /></div>}
                    </div>
                </div>
            </div>
            <ZFooterBar>
                <div className="footer-btn flex-1" onClick={onSubmit}>提交</div>
            </ZFooterBar>
            <CouponModal
                title="选择数字券"
                type="single"
                status={2}
                showSearch
                visible={couponVisible}
                onClose={() => setCouponVisible(false)}
                onConfirm={(v) => {
                    // console.log(v)
                    setFormData('numeral_coupon', {
                        id: v[0].id,
                        name: v[0].name
                    })
                }}
            />
            <ZModal
                title="选择配送方式"
                visible={deliveryVisible}
                onClose={() => setDeliveryVisible(false)}
            >
                <div className="delivery-list">
                    {logistics.map((item, index) => (
                        <div
                            key={index}
                            className={`item-content flex-space ${deliveryType === item.value ? 'active' : ''}`}
                            onClick={() => {
                                if (item.value === 2) {
                                    setSelfDelivery(0)
                                }
                                setDeliveryType(item.value)
                            }}
                        >
                            <span>{item.label}</span>
                            {deliveryType === item.value && <span className="icon-dagou"></span>}
                        </div>
                    ))}
                    <div
                        className={`item-content flex-space ${isSelfDelivery === 1 ? 'active' : ''} ${deliveryType === 2 ? 'disabled' : ''}`}
                        onClick={() => {
                            if (deliveryType != 2) {
                                setSelfDelivery(!isSelfDelivery ? 1 : 0)
                            }
                        }}
                    >
                        <span>到店自取</span>
                        {isSelfDelivery === 1 && <span className="icon-dagou"></span>}
                    </div>
                </div>
            </ZModal>
        </div>
    )
}

export default AddGoods