import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Image, Picker, Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import qs from 'query-string'
import { getExpressList, getOrderDetail, sendGood } from '../../apis/order'
import './index.scss'
import { copy } from '../../utils'
import { wxConfig } from '../../apis/common'

const Delivery = () => {
    const history = useHistory()
    const id = qs.parse(useLocation().search).id
    const [info, setInfo] = useState(null)
    const [form, setForm] = useState({
        express_no: '',
        express_code: '',
    })
    const [expressList, setExpressList] = useState([])

    useEffect(() => {
        if (id) {
            getData()
        }
        getExpressList().then(({ data }) => {
            setExpressList(data.map(t => ({
                label: t,
                value: t
            })))
        })
        wxConfig(['scanQRCode'])
    }, [])

    const getData = async() => {
        try {
            const { data } = await getOrderDetail({ id })
            setInfo(data)
        } catch (e) {
            console.log(e)
        }
    }

    const copyAddress = () => {
        copy(`${info.address.name} ${info.address.mobile} ${info.address.province}${info.address.city}${info.address.area}${info.address.address}`)
    }

    const onSubmit = async() => {
        if (!form.express_no || !form.express_code) return
        try {
            const { data } = await sendGood({
                id,
                ...form
            })
            Toast.show('发货成功')
            history.goBack()
        } catch (e) {
            console.log(e)
        }
    }

    const saoyisao = () => {
        window.wx.checkJsApi({
            jsApiList: ['scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function(res) {
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                if (res.checkResult.scanQRCode) {
                    window.wx.scanQRCode({
                        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果
                        scanType: ['qrcode', 'barcode'], // 可以指定扫二维码还是一维码，默认二者都有
                        success: function (res) {
                            var result = res.resultStr
                            setForm({
                                ...form,
                                express_no: result
                            })
                        },
                        fail: function (err) {
                            console.error(err.errmsg)
                        }
                    })
                } else {
                    Toast.show('请在微信浏览器打开')
                }
            }
        });
    }

    return (
        <div className="delivery">
            {!!info && <div className="card">
                <div className="card-inner">
                    <div className="item-title">订单编号：{info.order_no}</div>
                    {info.order_items.map(good => (<div className="item-good flex" key={good.id}>
                        <div className="item-good__media flex-shrink-0">
                            <Image className="item-good__img" src={good.product_cover} />
                        </div>
                        <div className="item-good__inner flex-1">
                            <div className="item-good__title ell2">{good.product_name}</div>
                            <div className="item-good__subtl">{good.product_spec_val}</div>
                        </div>
                        <div className="item-good__after flex-column align-end">
                            <div className="item-good__amount">¥{good.product_price}</div>
                            <div className="item-good__number">x{good.number}</div>
                        </div>
                    </div>))}
                    <div className="item-total">实收:<span className="b">¥{info.actual_amount}</span></div>
                    <div className="item-cells">
                        <div className="item-cell flex">
                            <div className="item-cell__label">收货信息</div>
                            {info.address ? <div className="item-cell__inner flex-1">
                                <div className="flex-a-center" style={{ marginBottom: 3 }}>
                                    <span>{info.address.name} {info.address.mobile}</span>
                                    <span className="icon-copy" style={{ marginLeft: 6 }} onClick={copyAddress}></span>
                                </div>
                                <div>{`${info.address.province} ${info.address.city} ${info.address.area} ${info.address.address}`}</div>
                            </div> : <div className="item-cell__inner flex-1">-</div>}
                        </div>
                        <div className="item-cell flex">
                            <div className="item-cell__label">买家留言</div>
                            <div className="item-cell__inner flex-1">{info.remake || '-'}</div>
                        </div>
                    </div>
                </div>
                <div className="card-title">快递单号</div>
                <div className="card-input flex-a-center">
                    <input type="text" placeholder="请输入快递单号" value={form.express_no} onChange={e => setForm({
                        ...form,
                        express_no: e.target.value
                    })} />
                    <div className="card-saoyisao" onClick={saoyisao}>
                        <span className="icon-saoyisao1"></span>
                        <span>扫码</span>
                    </div>
                </div>
                <div className="card-title">快递公司</div>
                <Picker
                    title="快递公司"
                    columns={[expressList]}
                    value={[form.express_code]}
                    onConfirm={v => {
                        setForm({
                            ...form,
                            express_code: v[0]
                        })
                    }}
                >
                    {(item, actions) => (
                        <div className="card-input flex-a-center" onClick={actions.open}>
                            <input type="text" placeholder="请选择快递公司" readOnly value={form.express_code} />
                            <RightOutline color="#8B8B92" fontSize={14} style={{ marginLeft: 6 }} />
                        </div>
                    )}
                </Picker>
                <div className={`card-btn ${(!form.express_no || !form.express_code) ? 'disabled' : ''}`} onClick={onSubmit}>确认发货</div>
            </div>}
        </div>
    )
}

export default Delivery