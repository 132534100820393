import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import GoodsModal from '../../components/goodsModal'
import './index.scss'
import qs from 'query-string'
import { getActivityAata, getActivityDetail2, getCommentList, storeJoin } from '../../apis/activity'

const CommentsItem = ({ data, parentId, onChange, onComment }) => {
    const _onChange = () => {
        typeof onChange === 'function' && onChange(data)
    }
    const _onComment = () => {
        typeof onComment === 'function' && onComment(data, parentId)
    }

    return (
        <div className="item-content flex align-start">
            <div className="item-media flex-shrink-0">
                <Image className="item-img" src={data.avatar} />
            </div>
            <div className="item-inner flex-1">
                <div onClick={_onComment}>
                    <div className="item-title flex-a-center">
                        <span>{data.nickname}</span>
                        {/* {data.isMain && <Text className="item-tag">主办方</Text>} */}
                    </div>
                    <div className="item-text">
                        {!!parentId && parentId != data.parent_id && <>
                            <span className="item-text__reply">回复</span>
                            <span className="item-text__name">{data.reply_nickname}</span>
                        </>}
                        <span className="item-text__inner">{data.content}</span>
                        <span className="item-text__time">{data.created_at}</span>
                    </div>
                </div>
                {data.parent_id === 0 && data.children_count > 0 && <>
                    {!!data.show ? data.children.map(item => (
                        <CommentsItem key={item.id} data={item} parentId={data.id} onComment={onComment} />
                    )) : <CommentsItem data={data.children[0]} parentId={data.id} onComment={onComment} />}
                    {!data.show && data.children_count > 1 && <div className="item-more" onClick={_onChange}>展开{data.children_count - 1}条回复</div>}
                </>}
            </div>
        </div>
    )
}

const ActivityDetail = () => {
    const { push } = useHistory()
    const { id } = qs.parse(useLocation().search)
    const [info, setInfo] = useState(null)
    const [aData, setAData] = useState({})
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    // const [videoHeight, setVideoHeight] = useState(0)

    const [visible, setVisible] = useState(false)
    const goodsModalRef = useRef()

    useEffect(() => {
        if (id) {
            getInfo()
            getData()
            loadMore()
        }
    }, [])

    const getInfo = async() => {
        try {
            const { data } = await getActivityDetail2({ activity_id: id })
            if (data.is_join_activity === 1) {
                data.self_store = data.store_list.shift()
            }
            setInfo(data)
            // if (data.video_width && data.video_height) {
            //     setVideoHeight(window.innerWidth / data.video_width * data.video_height)
            // }
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async() => {
        try {
            const { data } = await getActivityAata({ activity_id: id })
            setAData(data)
        } catch (e) {
            console.log(e)
        }
    }

    const showMore = (data) => {
        const index = list.findIndex(t => t.id === data.id)
        const _list = [...list]
        _list.splice(index, 1, {
            ...data,
            show: true
        })
        setList(_list)
    }

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getCommentList({
                activity_id: id,
                page: page.current,
                limit: 10,
                parent_id: 0,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const toJoin = async (arr) => {
        try {
            const { data } = await storeJoin({
                id: id,
                join_goods: JSON.stringify(
                    arr.map(t => ({
                        product_id: t.product_id,
                        product_name: t.product_name
                    }))
                )
            })
            Toast.show('参加成功，等待审核')
            getInfo()
            getData()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="activityDetail">
            {!!info && <div className="banner-swiper">
                {!!info.video_url ? <video
                    className="banner-video"
                    src={info.video_url}
                    poster={info.cover}
                    controls={true}
                    autoPlay={false}
                    style={{ objectFit: 'contain' }}
                /> : <Image className="banner-img" src={info.cover} fit="cover" style={{ borderRadius: 5 }} />}
            </div>}
            {!!info && <div className="content">
                <div className="title-box flex">
                    <div className="title flex-1">{info.title}</div>
                </div>
                <div className="time-box flex-a-center">
                    <div className="icon-time1"></div>
                    <div className="time-label">结束时间</div>
                    <div className="time-title">{info.end_time.replace(/-/g,'.')}</div>
                </div>
                <div className="block-title">活动详情</div>
                <div className="desc-box">{info.des}</div>
                <div className="block-title">活动内容</div>
                <div className="coupon-list">
                    {info.numeral_coupon_list.map(item => (<div className="coupon-item" key={item.id}>
                        <div className="item-tips">{item.coupon_type}</div>
                        {info.status === 6 && <div className="item-finish"></div>}
                        <div className="item-content flex-a-center">
                            <div className="item-amounts">
                                {item.type != 2 && <div className="unit">¥</div>}
                                <div className="amount">{item.money}</div>
                                {item.type == 2 && <div className="unit">折</div>}
                            </div>
                            <div className="item-inner flex-1">
                                <div className="item-title ell1">{item.name}</div>
                                <div className="item-subtl">{item.discount_desc}</div>
                            </div>
                            {/* {item.is_gain == 0 && <div className="item-btn">立即领取</div>}
                            {item.is_gain == 1 && <div className="item-btn used">去使用</div>} */}
                            {item.is_gain == 2 && <div className="item-btn used disabled">已使用</div>}
                            {item.is_gain == 3 && <div className="item-btn disabled">已结束</div>}
                        </div>
                        <div className="item-footer flex-space">
                            <div className="item-user flex-a-center">
                                {item.involved_user_list.length > 0 && <div className="item-user-imgs">
                                    {item.involved_num > 3 && <div className="item-user-more">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>}
                                    {item.involved_user_list.map(t => (<img key={t.id} className="item-user-img" src={t.avatar} />))}
                                </div>}
                                <div className="item-user-text">{item.involved_num}个用户领券</div>
                            </div>
                            {/* {info.status === 4 && <div className="flex-a-center">
                                <div className="item-oper flex-a-center">
                                    <div className="icon-chuangzuo"></div>
                                    <div>创作</div>
                                </div>
                                <div className="item-oper flex-a-center">
                                    <div className="icon-share"></div>
                                    <div>分享</div>
                                </div>
                            </div>} */}
                        </div>
                    </div>))}
                </div>
            </div>}
            {!!info && <div className="info-box">
                <div className="info-header info-card flex">
                    <div className="item-media flex-shrink-0">
                        <Image className="item-img" src={info.sponsor?.cover} />
                    </div>
                    <div className="flex-1">
                        <div className="item-label">主办方</div>
                        <div className="item-name">@{info.sponsor?.short_name}</div>
                    </div>
                </div>
                <div className="info-card">
                    <div className="info-title">{info.store_num}个报名商家</div>
                    <div className={`baoming-list ${info.is_join_activity === 1 ? 'isJoin' : ''}`}>
                        {info.is_join_activity === 1 && !!info.self_store && <div className="item-content isFixed">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={info.self_store.cover} />
                            </div>
                            <div className="item-title ell1">{info.self_store.name}</div>
                        </div>}
                        <div className="baoming-scrollview">
                            {info.store_list.map((item) => (
                                <div key={item.id} className="item-content">
                                    <div className="item-media flex-shrink-0">
                                        <Image className="item-img" src={item.cover} />
                                    </div>
                                    <div className="item-title ell1">{item.name}</div>
                                </div>
                            ))}
                            <div style={{ paddingLeft: 15 }}></div>
                        </div>
                    </div>
                    {info.is_join_activity === 1 && <div className="goods-list">
                        <div className="goods-scrollview">
                            {info.self_product_list.map(item => (
                                <div key={item.product_id} className="item-content">
                                    <div className="item-media">
                                        <Image className="item-img" src={item.cover} />
                                    </div>
                                    <div className="item-title ell1">{item.product_name}</div>
                                </div>
                            ))}
                        </div>
                        {info.join_activity_status === 5 && <div className="goods-tips flex-a-center">
                            <div className="icon-time2"></div>
                            <div>待审核</div>
                        </div>}
                        {info.join_activity_status === 10 && <div className="goods-tips flex-a-center">
                            <div className="icon-fail"></div>
                            <div>审核未通过：{info.join_activity_examine_msg}</div>
                        </div>}
                        {info.join_activity_status === 15 && <div className="goods-tips succ flex-a-center">
                            <div className="icon-succ"></div>
                            <div>审核通过</div>
                        </div>}
                    </div>}
                </div>
                <div className="info-card">
                    <div className="info-title">活动数据</div>
                    <div className="data-card">
                        <div className="item">
                            <div className="item-title">{aData.consume_price || 0}</div>
                            <div className="item-text">消费额(元)</div>
                        </div>
                        <div className="item">
                            <div className="item-title">{aData.give_num || 0}</div>
                            <div className="item-text">发券量</div>
                        </div>
                        <div className="item">
                            <div className="item-title">{aData.gain_num || 0}</div>
                            <div className="item-text">领券量</div>
                        </div>
                        <div className="item">
                            <div className="item-title">{aData.use_coupon_num || 0}</div>
                            <div className="item-text">用券量</div>
                        </div>
                        <div className="item">
                            <div className="item-title">{aData.share_num || 0}</div>
                            <div className="item-text">分享</div>
                        </div>
                        <div className="item">
                            <div className="item-title">{aData.activity_people_num || 0}</div>
                            <div className="item-text">参与人数</div>
                        </div>
                        <div className="item">
                            <div className="item-title">{aData.finds_num || 0}</div>
                            <div className="item-text">创作裂变</div>
                        </div>
                    </div>
                </div>
                <div className="info-title">聊一聊</div>
                <div className="comments-list">
                    {list.map(item => (
                        <CommentsItem data={item} key={item.id} onChange={showMore} />
                    ))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>}
            {!!info && <div className="footer">
                {info.status === 6 ? <div className="footer-btn disabled">已结束</div> : <>
                    {info.is_self_publish === 0 && <div className="footer-btn" onClick={() => push(`/addActivity?id=${id}`)}>复制新增</div>}
                    {info.join_activity_status === 1 && <div className="footer-btn" onClick={() => setVisible(true)}>立即参加</div>}
                    {info.join_activity_status === 5 && <div className="footer-btn disabled">待审核</div>}
                    {info.join_activity_status === 10 && <div className="footer-btn" onClick={() => setVisible(true)}>重新参加</div>}
                    {info.join_activity_status === 15 && <div className="footer-btn disabled">已参加</div>}
                    {info.join_activity_status === 20 && <div className="footer-btn disabled">已结束</div>}
                </>}
            </div>}
            <GoodsModal
                ref={goodsModalRef}
                title="选择商品"
                visible={visible}
                onClose={() => setVisible(false)}
                onConfirm={toJoin}
            ></GoodsModal>
        </div>
    )
}

export default ActivityDetail