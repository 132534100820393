import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import GoodsModal from '../../components/goodsModal'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import './index.scss'
import { getActivityList, storeJoin } from '../../apis/activity'

const ActivityManage = () => {
    const { push } = useHistory()
    const { info } = mainStore
    const [isAll, setIsAll] = useState(info.business_district ? 1 : 0)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [visible, setVisible] = useState(false)
    const [currentId, setCurrentId] = useState(0)
    const goodsModalRef = useRef()

    useEffect(() => {
        if (!info.id) {
            mainStore.getInfo()
        }
    }, [])

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [isAll])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getActivityList({
                page: _page,
                limit: 10,
                type_id: 1,
                business_district_id: isAll === 1 ? info.business_district.id : undefined,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const toJoin = async (arr) => {
        try {
            const { data } = await storeJoin({
                id: currentId,
                join_goods: JSON.stringify(
                    arr.map(t => ({
                        product_id: t.product_id,
                        product_name: t.product_name
                    }))
                )
            })
            Toast.show('参加成功，等待审核')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="activityManage">
            <div className="tabs flex">
                <div className={`item ${!isAll ? 'active' : ''}`} onClick={() => setIsAll(0)}>全部</div>
                {!!info.business_district && <div className={`item ${isAll ? 'active' : ''}`} onClick={() => setIsAll(1)}>
                    <span className="icon-location1"></span>{info.business_district.name}
                </div>}
            </div>
            <div className="list">
                {list.map(item => (<div className="item-content" key={item.id} onClick={() => push(`/activityDetail?id=${item.id}`)}>
                    <div className="flex">
                        <div className="item-media flex-shrink-0">
                            <Image className="item-img" src={item.cover} fit="cover" />
                            {item.status === 4 && <div className="item-status flex-a-center succ">
                                <div className="dot"></div>
                                <div className="flex-shrink-0">{item.status_html}</div>
                            </div>}
                            {[1,2].includes(item.status) && <div className="item-status erro">{item.status_html}</div>}
                            {item.status === 6 && <div className="item-status">{item.status_html}</div>}
                            {item.heat > 0 && <div className="item-hot flex-a-center">
                                <div className="icon-hot"></div>
                                <div>{item.heat}</div>
                            </div>}
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title ell2">{item.title}</div>
                            <div className="item-coupons">
                                {item.numeral_coupon_list.map(t => (<div className="item-coupon" key={t.id}>
                                    <div className="item-coupon-inner">{t.name}</div>
                                </div>))}
                            </div>
                            <div className="item-subtl flex-a-center">
                                <div className="icon-time"></div>
                                <div>{item.activity_date_html}</div>
                            </div>
                            <div className="flex flex-end">
                                {item.is_self_publish === 0 && <div className="item-btn" onClick={(e) => {
                                    e.stopPropagation()
                                    push(`/addActivity?id=${item.id}`)
                                }}>复制新增</div>}
                                {item.status === 4 && item.is_self_publish === 0 && item.is_join === 1 && item.is_in === 0 && (
                                    <div className="item-btn" onClick={(e) => {
                                        e.stopPropagation()
                                        goodsModalRef.current && goodsModalRef.current.reset()
                                        setCurrentId(item.id)
                                        setVisible(true)
                                    }}>立即参加</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="item-stats flex">
                        <div className="item-stat flex-1">
                            <div className="item-stat-title">{item.data.give_num}</div>
                            <div className="item-stat-text">发券量</div>
                        </div>
                        <div className="item-stat flex-1">
                            <div className="item-stat-title">{item.data.gain_num}</div>
                            <div className="item-stat-text">领券量</div>
                        </div>
                        <div className="item-stat flex-1">
                            <div className="item-stat-title">{item.data.use_coupon_num}</div>
                            <div className="item-stat-text">用券量</div>
                        </div>
                        <div className="item-stat flex-1">
                            <div className="item-stat-title">¥{item.data.consume_price}</div>
                            <div className="item-stat-text">消费金额</div>
                        </div>
                    </div>
                    <div className="item-footer flex-a-center flex-end">
                        <div className="item-footer-text">{item.store_count}个商家已报名</div>
                        {item.store_count > 0 && <div className="item-footer-imgs">
                            {item.store_list.map(t => (
                                <Image
                                    key={t.id}
                                    className="item-footer-img"
                                    src={t.cover}
                                />
                            ))}
                        </div>}
                    </div>
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            <div className="footer">
                <div className="footer-placeholder"></div>
                <div className="footer-inner flex">
                    <div className="footer-item flex-1" onClick={() => push('/activity?type=1')}>我发布的</div>
                    {info.is_service_center === 1 && <div className="footer-item flex-1" onClick={() => push('/activity?type=3')}>审批活动</div>}
                    <div className="footer-item flex-1" onClick={() => push('/activity?type=2')}>我参加的</div>
                </div>
            </div>
            <div className="add-btn" onClick={() => push('/addActivity')}><span className="icon-hands"></span>创建活动</div>
            <GoodsModal
                ref={goodsModalRef}
                title="选择商品"
                visible={visible}
                onClose={() => setVisible(false)}
                onConfirm={toJoin}
            ></GoodsModal>
        </div>
    )
}

export default observer(ActivityManage)