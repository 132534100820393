import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Image, Toast } from 'antd-mobile-v5'
import ZFooterBar from '@/components/zFooterBar'
import qs from 'query-string'
import { getOrderDetail, isOrderInProgress, refundSubmit } from '../../apis/order'
import { copy, timestampToString, timeStrToTimestamp } from '../../utils'
import './index.scss'

const Refund = () => {
    const history = useHistory()
    const id = qs.parse(useLocation().search).id
    const [info, setInfo] = useState(null)

    useEffect(() => {
        if (id) {
            getData()
        }
    }, [])

    const getData = async() => {
        try {
            const { data } = await getOrderDetail({ id })
            const t = (48*60*60*1000) - (Date.now() - data.pay_at * 1000)
            let isRefund = false
            if (isOrderInProgress(data.status) || (data.status === 1 && t > 0)) {
                isRefund = true
            }
            data.isRefund = isRefund
            setInfo(data)
        } catch (e) {
            console.log(e)
        }
    }

    const onSubmit = async() => {
        if (!info) return
        try {
            const params = {
                order_id: id,
                type: 1,
                items: info.order_items.map(t => ({ id: t.id, num: t.number })),
                return_method: 0
            }
            await refundSubmit(params)
            Toast.show('提交成功')
            history.goBack()
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <div className="refund">
            {!!info && <div className="content">
                <div className="card">
                    <div className="card-header flex-space">
                        <div className="card-title">商品信息</div>
                        <div className="userInfo flex-a-center">
                            <img className="userInfo-img" src={info.user?.avatar} />
                            <div className="userInfo-name">{info.user?.username || '微信用户'}</div>
                            <span className="icon-message"></span>
                        </div>
                    </div>
                    <div className="card-inner">
                        {info.order_items.map(good => (<div className="item-good flex" key={good.id}>
                            <div className="item-good__media flex-shrink-0">
                                <Image className="item-good__img" src={good.product_cover} />
                            </div>
                            <div className="item-good__inner flex-1">
                                <div className="item-good__title ell2">{good.product_name}</div>
                                <div className="item-good__subtl">{good.product_spec_val}</div>
                            </div>
                            <div className="item-good__after flex-column align-end">
                                <div className="item-good__amount">¥{good.product_price}</div>
                                <div className="item-good__number">x{good.number}</div>
                            </div>
                        </div>))}
                        <div className="item-cells">
                            <div className="item-cell flex-space">
                                <div>商品总价</div>
                                <div className="b">¥{info.product_amount}</div>
                            </div>
                            <div className="item-cell flex-space">
                                <div>打包费</div>
                                <div className="b">+¥{info.package_price}</div>
                            </div>
                            <div className="item-cell flex-space">
                                <div>{info.order_type === 0 ? '运费' : '配送费'}</div>
                                <div className="b">+¥{info.freight}</div>
                            </div>
                            {info.numeral_coupon_amount > 0 && <div className="item-cell flex-space">
                                <div>平台优惠券</div>
                                <div className="b">-¥{info.numeral_coupon_amount}</div>
                            </div>}
                        </div>
                        <div className="flex-a-center flex-end">
                            <span className="item-total">退款金额 ¥{info.actual_amount}</span>
                        </div>
                    </div>
                </div>
                <div className="cell-card">
                    <div className="item flex-space">
                        <span>订单编号</span>
                        <div className="flex-a-center">
                            <span>{info.order_no}</span>
                            <span className="icon-copy" style={{ marginLeft: 3 }} onClick={() => copy(info.order_no)}></span>
                        </div>
                    </div>
                    <div className="item flex-space">
                        <span>下单时间</span>
                        <span>{timestampToString(info.created_at * 1000, 2)}</span>
                    </div>
                    <div className="item flex-space">
                        <span>付款时间</span>
                        <span>{timestampToString(timeStrToTimestamp(info.pay_at), 2)}</span>
                    </div>
                </div>
            </div>}
            {!!info && info.isRefund && <ZFooterBar className="flex-end">
                <div className="flex-column align-end">
                    <div className="footer-title flex-a-center">退款金额<span className="amount">￥{(info.actual_amount - info.numeral_coupon_amount).toFixed(2)}</span></div>
                    <div className="footer-text">退款将原路返回</div>
                </div>
                <div className="footer-btn main" onClick={onSubmit}>提交</div>
            </ZFooterBar>}
        </div>
    )
}

export default Refund