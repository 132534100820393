import http from './request'

// 订单状态描述
export const ORDER_STATUS = {
    0: '未支付',
    1: '已完成',
    2: '待发货',
    3: '已取消',
    4: '配送中',
    5: '退款中',
    6: '已退款',
    7: '商家取消',
    8: '骑手已接单',
    9: '骑手已到店',
    10: '骑手已送达',
    11: '配送异常',
    71: '配送方取消',
    72: '骑手取消',
}

// 退款 remark_type 描述
export const REMARK_TYPE_STATUS = {
    11: '商家发起退款',
    12: '骑手发起退款',
    22: '配送方发起退款'
}

// 订单是否进行中
export const isOrderInProgress = (status) => [2, 4, 8, 9, 10, 11].includes(+status)

// 订单是否已取消
export const isOrderCanceled = (status) => [3, 7, 71, 72].includes(+status)

// 订单管理-退款、退货管理
export const getRefundList = (params) => http.get('/store/order/mobile-refund-list', params)

// 退款-退货同意\拒绝
export const refundAudit = (params) => http.post('/store/order/refund-audit', params)

// 物流订单/外卖订单列表
export const getOrderList = (params) => http.get('/store/mobile-order/index', params)

// 订单详情
export const getOrderDetail = (params) => http.get('/store/mobile-order/show', params)

// 退款-退货提交
export const refundSubmit = (params) => http.post('/store/order/refund-apple', params)

// 出餐
export const diningOut = (id) => http.post('/store/order/completed', { id })

// 快递公司-列表
export const getExpressList = () => http.get('/store/mobile-order/express')

// 发货-填写快递物流信息
export const sendGood = (params) => http.post('/store/order/send-good', params)

// 修改订单发货地址
export const editOrderAddress = (params) => http.post('/store/mobile-order/edit-order-address', params)